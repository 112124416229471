<template>
  <input 
    type="text" 
    v-only_ru_letters 
    :value="value" 
    v-on="inputListeners">
</template>
  
<script>
export default {
  name: "baseOnlyRuInput",
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    inputListeners() {
      return Object.assign({},
        this.$listeners,
        {
          input: (event) => {
            this.$emit('input', event.target.value)
          }
        }
      )
    }
  },
  directives: {
    only_ru_letters: {
      bind(el) {
        el.oninput = function (e) {
          if (!e.isTrusted) {
            return;
          }
          var mapKey = {
            'q': 'й',
            'w': 'ц',
            'e': 'у',
            'r': 'к',
            't': 'е',
            'y': 'н',
            'u': 'г',
            'i': 'ш',
            'o': 'щ',
            'p': 'з',
            '[': 'х',
            ']': 'ъ',
            'a': 'ф',
            's': 'ы',
            'd': 'в',
            'f': 'а',
            'g': 'п',
            'h': 'р',
            'j': 'о',
            'k': 'л',
            'l': 'д',
            ';': 'ж',
            '\'': 'э',
            'z': 'я',
            'x': 'ч',
            'c': 'с',
            'v': 'м',
            'b': 'и',
            'n': 'т',
            'm': 'ь',
            ',': 'б',
            '.': 'ю',
            'Q': 'Й',
            'W': 'Ц',
            'E': 'У',
            'R': 'К',
            'T': 'Е',
            'Y': 'Н',
            'U': 'Г',
            'I': 'Ш',
            'O': 'Щ',
            'P': 'З',
            '{': 'Х',
            '}': 'Ъ',
            'A': 'Ф',
            'S': 'Ы',
            'D': 'В',
            'F': 'А',
            'G': 'П',
            'H': 'Р',
            'J': 'О',
            'K': 'Л',
            'L': 'Д',
            ':': 'Ж',
            '\'': 'Э',
            'Z': 'Я',
            'X': 'Ч',
            'C': 'С',
            'V': 'М',
            'B': 'И',
            'N': 'Т',
            'M': 'Ь',
            ',': 'Б',
            '<': 'Б',
            '.': 'Ю',
            '>': 'Ю',
          };
          var str = this.value.toUpperCase();
          var r = '';
          for (var i = 0; i < str.length; i++) {
            r += mapKey[str.charAt(i)] || str.charAt(i);
          }
          this.value = r.replace(/[^А-Яа-яЁё -]/gi, '').replace(/\s+/gi, ' ').replace(/\-+/gi, '-');
          el.dispatchEvent(new Event('input'));
        }
      }
    }
  }
}
</script>
